
@media (min-width: 1024px) {
    .page_container__7giYo {
      max-width: 970px;
      min-width: 938px;
      width: auto;
    }
  }

  .page_name__3uR8A {
    position: relative;
    display: inline-block;
    text-align: center;
    color: #191919;
    max-width: 100%;
    white-space: normal;
    word-wrap: break-word;
    overflow-wrap: break-word;
  }
  .page_name__3uR8A:after,
  .page_name__3uR8A:before {
    content: "";
    display: block;
    width: 40px;
    height: 1px;
    background: #191919;
    opacity: 0.5;
    position: absolute;
    top: 49%;
  }
  .page_name__3uR8A:before {
    left: -55px;
  }
  .page_name__3uR8A:after {
    right: -55px;
  }

  .SectionCheck_iconContainer__hnbVH {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #003c64;
    border-radius: 50%;
    width: 24px;
    height: 24px;
  }
  .page_imageContainer__fyTKY {
    width: 353px;
    max-width: 100%;
    height: 353px;
    text-align: center;
    margin: 0 auto;
    -o-object-fit: contain;
    object-fit: contain;
    position: relative;
  }
  .page_imageContainer__fyTKY .page_badgeLogo__cOzKx {
    height: 19px;
    margin-bottom: 0.5rem;
  }
  .page_imageContainer__fyTKY .page_score__AIOCh {
    font-size: 1.875rem;
    line-height: 2.25rem;
    font-weight: 700;
  }
  .page_imageContainer__fyTKY .page_cefr__WDeEY {
    max-width: 192px;
    font-size: 1.125rem;
    line-height: 1.75rem;
    font-weight: 500;
  }

  .page_imageContainer__fyTKY > div {
    position: absolute;
    top: 0;
    z-index: 20;
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.25rem;
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }

  .bg-background {
    --tw-bg-opacity: 1;
    background-color: rgb(224 238 251 / var(--tw-bg-opacity));
  }

  .ScoreComparisonTable_unselectedItem___E4kM {
    color: #9898ab;
    background-color: #fff;
  }
  .bg-chart-green {
    --tw-bg-opacity: 1;
    background-color: rgb(58 200 101 / var(--tw-bg-opacity));
  }
  .stroke-chart-green {
    --tw-stroke-opacity: 1;
    stroke: rgb(58 200 101 / var(--tw-stroke-opacity));
  }
  .text-chart-green {
    --tw-text-opacity: 1;
    color: rgb(58 200 101 / var(--tw-text-opacity));
  }
  .bg-chart-purple {
    --tw-bg-opacity: 1;
    background-color: rgb(102 73 237 / var(--tw-bg-opacity));
  }
  .stroke-chart-purple {
    --tw-stroke-opacity: 1;
    stroke: rgb(102 73 237 / var(--tw-stroke-opacity));
  }
  .text-chart-purple {
    --tw-text-opacity: 1;
    color: rgb(102 73 237 / var(--tw-text-opacity));
  }
  .bg-chart-violet {
    --tw-bg-opacity: 1;
    background-color: rgb(227 74 230 / var(--tw-bg-opacity));
  }
  .bg-chart-red {
    background-color: rgb(251 28 115);
  }
  .stroke-chart-violet {
    --tw-stroke-opacity: 1;
    stroke: rgb(227 74 230 / var(--tw-stroke-opacity));
  }
  .text-chart-violet {
    --tw-text-opacity: 1;
    color: rgb(227 74 230 / var(--tw-text-opacity));
  }
  .bg-chart-cyan {
    --tw-bg-opacity: 1;
    background-color: rgb(5 179 192 / var(--tw-bg-opacity));
  }
  .stroke-chart-cyan {
    --tw-stroke-opacity: 1;
    stroke: rgb(5 179 192 / var(--tw-stroke-opacity));
  }
  .text-chart-cyan {
    --tw-text-opacity: 1;
    color: rgb(5 179 192 / var(--tw-text-opacity));
  }
  .bg-chart-orange-dark {
    --tw-bg-opacity: 1;
    background-color: rgb(254 102 18 / var(--tw-bg-opacity));
  }
  .stroke-chart-orange-dark {
    --tw-stroke-opacity: 1;
    stroke: rgb(254 102 18 / var(--tw-stroke-opacity));
  }
  .text-chart-orange-dark {
    --tw-text-opacity: 1;
    color: rgb(254 102 18 / var(--tw-text-opacity));
  }
  .bg-chart-orange {
    --tw-bg-opacity: 1;
    background-color: rgb(255 138 1 / var(--tw-bg-opacity));
  }
  .stroke-chart-orange {
    --tw-stroke-opacity: 1;
    stroke: rgb(255 138 1 / var(--tw-stroke-opacity));
  }
  .text-chart-orange {
    --tw-text-opacity: 1;
    color: rgb(255 138 1 / var(--tw-text-opacity));
  }
  .bg-chart-yellow {
    --tw-bg-opacity: 1;
    background-color: rgb(255 184 0 / var(--tw-bg-opacity));
  }
  .stroke-chart-yellow {
    --tw-stroke-opacity: 1;
    stroke: rgb(255 184 0 / var(--tw-stroke-opacity));
  }
  .text-chart-yellow {
    --tw-text-opacity: 1;
    color: rgb(255 184 0 / var(--tw-text-opacity));
  }

  @media (min-width: 1024px) {
    .shadow-paper {
      --tw-shadow: 0px 2px 16px 0px rgba(25, 25, 25, 0.15);
      --tw-shadow-colored: 0px 2px 16px 0px var(--tw-shadow-color);
      box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
        var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    }
  }

  .Footer_title__7ywZX {
    position: relative;
    display: inline-block;
    text-align: center;
    color: #191919;
    line-height: 1;
    margin: 0;
  }
  .Footer_title__7ywZX:after,
  .Footer_title__7ywZX:before {
    content: "";
    display: block;
    width: 40px;
    height: 1px;
    background: #191919;
    opacity: 0.5;
    position: absolute;
    top: 49%;
  }

  .Footer_title__7ywZX:before {
    left: -55px;
  }
  .Footer_title__7ywZX:after {
    right: -55px;
  }
  .CircularProgress_placeholder__EUxMP {
    stroke-dashoffset: 0;
    stroke: #eee;
  }
  .CircularProgress_circle__QiVyT {
    stroke-linecap: round;
    position: absolute;
    fill: none;
  }

  @keyframes progress {
    from {
      stroke-dasharray: 0 257.611;
    }
    to {
      stroke-dasharray: var(--progress-value) 257.611;
    }
  }

  .CircularProgress_circle__QiVyT {
    stroke-dasharray: 0 257.611;
    opacity: 0;
    animation: progress 1s ease-out forwards,
      fadeIn 0.5s ease-out forwards;
    animation-timeline: view();
    animation-range: entry 25% cover 50%;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @media (prefers-reduced-motion: reduce) {
    .CircularProgress_circle__QiVyT {
      animation: none;
      stroke-dasharray: var(--progress-value) 257.611;
      opacity: 1;
    }
  }