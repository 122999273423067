body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .card {
    @apply p-5 text-customGreen;
    background-color: theme(colors.customOrange);
  }

  .boxShadow {
    box-shadow: 1px 2px 20px 10px rgb(177 174 174 / 14%);
  }

  .avatar {
    margin-left: -1rem;
    height: 3rem;
    width: 3rem;
    border-radius: 9999px;
    border-width: 1px;
    --tw-border-opacity: 1;
    border-color: rgb(255 255 255 / var(--tw-border-opacity));
  }
}

/* */

/**
 * Tabs Container

.tabs-container {
  padding: 2rem;
} */

/**
 * Tabs Block
 */
.tabs-block {
  display: flex;
  align-items: center;
  justify-content: center;
}

/**
 * Tabs
 */
.tabs {
  display: flex;
}

.tabs > ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 20%;
}

.tabs > ul li {
  display: block;
}

.tabs > ul li:not(:last-child) {
  border-bottom: 1px solid #e7e7e7;
}

.tabs > ul li a,
.tabs > ul li a:visited {
  display: flex;
  border-bottom: none;
  text-decoration: none;
  background-color:#d4d4d4;
  color: #050505;
  padding: 1rem 1.5rem;
  transition: all 0.2s ease-in-out;
  word-wrap: break-word;
}

.tabs > ul li a:hover,
.tabs > ul li a:focus,
.tabs > ul li a:active {
  border-bottom: none;
  outline: 0;
}

.tabs > ul li a.active {
  background-color: #e7e7e7;
  color: #000;
}

.tabs > ul li a:hover:not(.active) {
  color: #e31837;
}

.tabs > ul li a > span {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.tabs > ul li a > span.tab-label {
  display: none;
}

.tabs section {
  width: 85%;
  background-color: #e7e7e7;
  padding: 1rem;
  color: #000;
  display: none;
  word-wrap: break-word;
  /* border-bottom: 6px solid #0067b8;*/
}

.tabs section > * {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.2s, transform 0.2s;
}

.tabs section > *:nth-child(1) {
  transition-delay: 0.2s;
}

.tabs section > *:nth-child(2) {
  transition-delay: 0.3s;
}

.tabs section > *:nth-child(3) {
  transition-delay: 0.4s;
}

.tabs section > *:nth-child(4) {
  transition-delay: 0.5s;
}

.tabs section > *:nth-child(5) {
  transition-delay: 0.6s;
}

.tabs section > *:nth-child(6) {
  transition-delay: 0.7s;
}

.tabs section > *:nth-child(7) {
  transition-delay: 0.8s;
}

.tabs section > *:nth-child(8) {
  transition-delay: 0.9s;
}

.tabs section > *:nth-child(9) {
  transition-delay: 1s;
}

.tabs section > *:nth-child(10) {
  transition-delay: 1.1s;
}

.tabs section > *:nth-child(11) {
  transition-delay: 1.2s;
}

.tabs section > *:nth-child(12) {
  transition-delay: 1.3s;
}

.tabs section > *:nth-child(13) {
  transition-delay: 1.4s;
}

.tabs section > *:nth-child(14) {
  transition-delay: 1.5s;
}

.tabs section > *:nth-child(15) {
  transition-delay: 1.6s;
}

.tabs section > *:nth-child(16) {
  transition-delay: 1.7s;
}

.tabs section > *:nth-child(17) {
  transition-delay: 1.8s;
}

.tabs section > *:nth-child(18) {
  transition-delay: 1.9s;
}

.tabs section > *:nth-child(19) {
  transition-delay: 2s;
}

.tabs section > *:nth-child(20) {
  transition-delay: 2.1s;
}

.tabs section.active {
  display: block;
}

.tabs section.active-content > * {
  opacity: 1;
  transform: translateY(0);
}
@media (min-width: 576px) {
  .tabs > ul {
    width: 225px;
  }

  .tabs > ul li a {
    flex-wrap: wrap;
  }

  .tabs > ul li a > span {
    width: 100%;
  }

  .tabs > ul li a > span.tab-label {
    width: 100%;
    display: block;
    margin-top: 0.2rem;
  }

  .tabs section {
    width: calc(100% - 150px);
    padding: 1.5rem 2rem 2rem;
  }
  .tabs section p {
    padding-top: 20px;
  }
}
/* @media (min-width: 768px) {
  .tabs-container {
    padding: 4rem 4rem;
  }
} */
@media (min-width: 992px) {
  .tabs {
    width: 800px;
  }
}

/*****************************/
@keyframes pinExtensionMoving {
  0% {
    top: 5px;
  }

  25% {
    top: -15px;
  }

  50% {
    top: 5px;
  }

  75% {
    top: -15px;
  }

  to {
    top: 5px;
  }
}
.animate-pinExtensionMoving {
  animation: pinExtensionMoving 2s ease-in-out infinite;
}
